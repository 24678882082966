import React, { useState } from 'react';
import styles from './crousel.module.css';


import toothImg from '../../assets/course/tooth.svg';
import professorImg from '../../assets/course/Professor.svg'
const SepcializeCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const doctors = [
      {
        name: 'Jeffrey Hoos',
        experience: '8 years Exp.',
        image: professorImg,
      },
      {
        name: 'Ryan Palatino',
        experience: '8 years Exp.',
        image: professorImg,
      },
    ];
  
    const goToSlide = (index) => {
      setCurrentIndex(index);
    };
  
    return (
      <div className={styles.carousel}>
        <div className={styles.carouselInner}>
          {doctors.map((doctor, index) => (
            <div className={`${styles.doctor} ${index === currentIndex ? styles.show : styles.hide}`} key={index}>
              <div className={styles.detailsDiv}>
                <h2 style={{ marginTop: '20px', marginBottom: '10px', textDecoration: 'underline white solid 1px', textUnderlineOffset: '5px' }}>
                  {doctor.name}
                </h2>
                <div className={styles.infoGrid}>
                  <span>
                    <img src={toothImg} alt="tooth" />
                    <h6>{doctor.experience}</h6>
                  </span>
                  <span>
                    <img src={toothImg} alt="tooth" />
                    <h6>{doctor.experience}</h6>
                  </span>

                  <span>
                    <img src={toothImg} alt="tooth" />
                    <h6>{doctor.experience}</h6>
                  </span>
                </div>
              </div>
              <img src={doctor.image} alt={doctor.name} className={styles.docImg} />
            </div>
          ))}
        </div>
  
        {/* Dots for navigating between slides */}
        <div className={styles.dots}>
          {doctors.map((_, index) => (
            <div
              key={index}
              className={`${styles.dot} ${currentIndex === index ? styles.active : ''}`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>
    );
  
  };

export default SepcializeCarousel;
