import React from "react";
import styles from "./certi.module.css";
import Textfield from "../../components/Textfield";
import Button from "../../components/Buttons";
import { IoPlay } from "react-icons/io5";
import bannerImage from "../../assets/banner.png";
import { HashLink as HLink } from "react-router-hash-link";
const CourseCertificates = () => {
  return (
    <div className={styles.certiContainer}>
      <Textfield colorType="blue" type="h1">
        About this Professional Certificate Course
      </Textfield>
      <Textfield
        colorType="blue"
        type="h5"
        style={{
          marginTop: 10,
          fontWeight: 400,
          width: 900,
          textAlign: "center",
        }}
      >
        This comprehensive professional certificate course equips dental
        professionals with the expertise to integrate intra-oral scanning into
        their practice seamlessly. Learn the fundamentals of IOS scanner setup,
        essential retraction and isolation techniques for optimal scan quality,
        and best practices for accuracy and quality control. Develop proficiency
        in scanner handling, machine maintenance, and troubleshooting common
        issues. Understand the characteristics of a good IOS scan and the ideal
        step-by-step process. Explore digital workflow integration, effective
        communication strategies, and how to prevent and address potential
        failures.
      </Textfield>
      <div className={styles.btnMain}>
        {/* <div className={styles.playBtn}>
          <Textfield colorType="white" style={{ fontWeight: 400 }}>
            Watch the Video
          </Textfield>
          <div className={styles.circle}>
            <IoPlay color="#fff" size={18} />
          </div>
        </div> */}
        <HLink smooth to="#contactus">   <Button>book a free demo class </Button> </HLink>
      </div>
      <div className={styles.stackedContainer}>
        <img src={bannerImage} alt="" className={styles.left2} />
        <img src={bannerImage} alt="" className={styles.left} />
        <img src={bannerImage} alt="" className={styles.center} />
        <img src={bannerImage} alt="" className={styles.right} />
        <img src={bannerImage} alt="" className={styles.right2} />
      </div>
    </div>
  );
};

export default CourseCertificates;
